import React from "react"
import Helmet from "react-helmet"
import { withPrefix, graphql, Link } from "gatsby"
import BloggerFooterLink from "../blogger-link.component"
import UtterancesComments from "../utterances-comments.component"
import HeaderPost from "../header-post.component"
import { MDXRenderer } from "gatsby-plugin-mdx";
import "../../../static/styles/prism.css"
import Footer from "../footer.component"
import BookListSidebar from "../book-list-sidebar"
import PageSidebar from "../page-sidebar.component"
import Navigation from "../navigation.component"

const BlogPage =({ pageContext, data }) => { 

  const { mdx } = data // data.markdownRemark holds your post data
  const { frontmatter, body } = mdx
  const bloggers = data.bloggers.edges.reduce((model, blogger)=> {
    model[blogger.node.basics.id] = {
        "name": blogger.node.basics.name,
        "email": blogger.node.basics.email
    }
    return model;        
  }, {});
  return (    
    <main className="">
      <Helmet>
        <title>bitCobblers - {frontmatter.title}</title>                         
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css"></link>        
        <script src={withPrefix('/prism.js')} type="text/javascript" />
        <html lang="en" />
      </Helmet>
      <HeaderPost></HeaderPost>
      <Navigation />      
      <PageSidebar sidebar={<BookListSidebar books={pageContext.books}/>}>    
        <article className="space-y-4">                    
          <time className="text-gray-500" dateTime={frontmatter.date}>{frontmatter.date}</time>                
          <h1>{frontmatter.title}</h1>                          
          <MDXRenderer>{body.toString()}</MDXRenderer>
          <BloggerFooterLink blogger={frontmatter.blogger} list={bloggers}></BloggerFooterLink>
        </article>
        <UtterancesComments></UtterancesComments>
      </PageSidebar>
      {/* <Footer></Footer> */}      
    </main>
  )
}
export default BlogPage
export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        date
        slug   
        blogger   
        title
        tags
      }
    }
    bloggers: allResumeJson {
      edges {
        node {
          basics {
            id
            email
            name
          }
        }
      }
    }
  }
`