import React from "react"
import BookImage from "./bookImage.component"

const BookListSidebar = ({books, size}) => {    
    var display = [];
    size = size ?? 2;
    var random = (max) => {
      return Math.floor(Math.random() * max);
    }

    while (display.length < size && books.length > 0) {
      var i = random(books.length);      
      display.push(books[i]);      
      books.splice(i, 1);
    }
    
    return (                  
      <div id="container"
          className="flex md:flex-col space-y-5 justify-center">          
          <h2>
            Related Books
          </h2>
          {display.map(book => (                    
            <BookImage className="w-22 mx-auto w-min-22" book={book}>              
            </BookImage>                                    
        ))}
      </div>    
)}

export default BookListSidebar